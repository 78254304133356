<template>
  <v-card flat>

    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <v-container >
        <v-row>
            <v-col><p>Crea o edita nuevos análisis para tu laboratorio de forma sencilla</p></v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
                label="Nombre del ensayo"
                class="c-view-title__input inline-block"
                outlined
                dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Estado"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Tipo de ensayo"
                outlined
                dense
            ></v-select>
          </v-col>

        </v-row>
        <v-row>
            <v-col><p>Información técnicas sobre tu ensayo</p></v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Clasificacion del ensayo"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
                label="Descripcion del ensayo"
                class="c-view-title__input inline-block"
                outlined
                dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
                label="Descripcion de la metodologia"
                class="c-view-title__input inline-block"
                outlined
                dense
            ></v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Parametros"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Tipo de Tecnica"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
                :items="estatus"
                item-text="texto"
                item-value="id"
                label="Tipo de Resutado"
                outlined
                dense
            ></v-select>
          </v-col>

        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
export default {
  data() {
    const defaultForm = Object.freeze({
      first: '',
      last: '',
      bio: '',
      favoriteAnimal: '',
      age: null,
      terms: false,
    });

    return {
      form: { ...defaultForm },
      rules: {
        age: [
          (val) => val < 10 || 'I don\'t believe you!',
        ],
        animal: [(val) => (val || '').length > 0 || 'This field is required'],
        name: [(val) => (val || '').length > 0 || 'This field is required'],
      },
      estatus: [{ id: 1, texto: 'Activo' }, { id: 2, texto: 'Inctivo' }],
      conditions: false,
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.',
      snackbar: false,
      terms: false,
      defaultForm,
    };
  },

  computed: {
    formIsValid() {
      return (
        this.form.first
          && this.form.last
          && this.form.favoriteAnimal
          && this.form.terms
      );
    },
  },

  methods: {
    resetForm() {
      this.form = { ...this.defaultForm };
      this.$refs.form.reset();
    },
    submit() {
      this.snackbar = true;
      this.resetForm();
    },
  },
};
</script>
